<template>
  <base-layout>
    <WhatsNewAddEdit
      :isInitDataLoading="isInitDataLoading"
      :systemVersionStatusChoices="systemVersionStatusChoices"
      :releaseNoteTypeChoices="releaseNoteTypeChoices"
      :isScreenFull="getTableMode === 'full' ? false : true"
      @add-edit="$store.dispatch('fsTable/fetchData')"
    />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus
            title="Add Release Notes"
            @plus="add"
            :hide-plus="false"
          />
        </div>
      </div>

      <template>
        <FSTable
          :fst-id="fstId"
          :qso="qso"
          :endpoint="indexDataEndpoint"
          :headers="getTableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" textFallbackAlways>
                  <FSTableRowItem :text="item.id" :truncate="-5" />
                  <FSTableRowItem :text="item.version" />
                  <FSTableRowItem :text="item.title" />
                  <FSTableRowItem :text="item.release_date" date />
                  <FSTableRowItem :text="item.release_notes_url" />
                  <FSTableRowItem>
                    <x-status
                      :text="item.status_display_text"
                      :variant="getItemVariant(item.status_display_text)"
                    />
                  </FSTableRowItem>
                  <FSTableRowItem
                    ><div class="flex items-center">
                      <oto-edit-icon @click="edit(item)" />
                      <MoreActions
                        :id="itemIndex"
                        :data="item"
                        @refresh="$store.dispatch('fsTable/fetchData')"
                      /></div
                  ></FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  textFallbackAlways
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem :text="item.version" />
                  <FSTableRowItem>
                    <x-status
                      :text="item.status_display_text"
                      :variant="getItemVariant(item.status_display_text)"
                    />
                  </FSTableRowItem>
                  <FSTableRowItem
                    ><div class="flex items-center">
                      <oto-edit-icon @click="edit(item)" />
                      <MoreActions
                        :id="itemIndex"
                        :data="item"
                        @refresh="$store.dispatch('fsTable/fetchData')"
                      /></div
                  ></FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Id</div>
                      <div class="col-span-5 right-text">
                        {{ $truncate(item.id, -5) }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Title</div>
                      <div class="col-span-5 right-text">{{ item.title }}</div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Release Date</div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.release_date,
                            'D MMM, YY hh:mm:ss a'
                          ) || `--`
                        }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Release Url</div>
                      <div class="col-span-5 right-text">
                        {{ item.release_notes_url || `--` }}
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </base-layout>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import WhatsNewAddEdit from '@/views/whats-new/WhatsNewAddEdit.vue'
import { WhatsNewConfig } from '@/config/WhatsNewConfig.js'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import MoreActions from '@/views/whats-new/shared/MoreActions.vue'
import { EventBus } from '@/utils'
import XStatus from '@/components/badge/XStatus'
import { getFormattedDateTime } from '@/utils/datetime'
// import { SocketConfig } from '@/config/SocketConfig'
// import ReconnectingWebsocket from 'reconnectingwebsocket'
export default {
  name: 'WhatsNewIndex',
  components: {
    ContentSection,
    TitlePlus,
    BaseLayout,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    WhatsNewAddEdit,
    OtoEditIcon,
    MoreActions,
    XStatus,
  },
  data() {
    return {
      fstId: 'whats-new-index',
      qso: { append: '', prepend: '?' },
      indexDataEndpoint: WhatsNewConfig.api.systemVersion.index(),

      windowWidth: window.innerWidth,

      tableHeaders: [
        { text: 'Id', width: '10%', sort: null },
        { text: 'Version', width: '10%', sort: null },
        { text: 'Title', width: '10%', sort: null },
        { text: 'Release Date', width: '10%', sort: null },
        { text: 'Release Url', width: '10%', sort: null },
        { text: 'Status', width: '5%', sort: null },
        { text: 'Action', width: '5%', sort: null },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'Version', width: '10%', sort: null },
        { text: 'Status', width: '10%', sort: null },
        { text: 'Action', width: '5%', sort: null },
      ],
      opened: [],

      systemVersionStatusChoices: [],
      releaseNoteTypeChoices: [],
      isInitDataLoading: false,
    }
  },
  async created() {
    await this.fetchInitDataForAddEdit()
    // this.connectToWebsocket()
    // await this.generateFilterOptions()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    // this.disconnectToWebsocket()
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    getFormattedDateTime,
    getItemVariant(data) {
      if (data === 'Published') return 'green'
      else return 'gray'
    },
    async fetchInitDataForAddEdit() {
      this.isInitDataLoading = true
      const systemVersionStatusChoicesReq = this.$http.get(
        WhatsNewConfig.api.systemVersion.statusChoices()
      )
      const releaseNoteTypeChoicesReq = this.$http.get(
        WhatsNewConfig.api.releaseNotes.typeChoices()
      )
      const requests = [
        systemVersionStatusChoicesReq,
        releaseNoteTypeChoicesReq,
      ]
      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            this.systemVersionStatusChoices = responses[0].data
            this.releaseNoteTypeChoices = responses[1].data

            console.log('fetchInitDataForAddEdit', responses)
          })
        )
        .catch((err) => {
          console.log('fetchInitDataForAddEdit-err', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed to load Add/Edit initial data',
              text: err.response.data,
            },
            3000
          )
        })
        .finally(() => (this.isInitDataLoading = false))
    },
    add() {
      EventBus.$emit(WhatsNewConfig.events.editingData, {})
      dispatchEvent(new Event(WhatsNewConfig.events.sorToggle))
    },
    edit(item) {
      EventBus.$emit(WhatsNewConfig.events.editingData, {
        itemId: item.id,
      })
      dispatchEvent(new Event(WhatsNewConfig.events.sorToggle))
    },
    // connectToWebsocket() {
    //   console.log('Connecting to Websocket from NotificationIndex')
    //   const authToken = localStorage.getItem('token') || null
    //   if (!authToken) {
    //     console.error('Cannot connect to notification because not logged in')
    //     return
    //   }
    //   let sockUrl =
    //     SocketConfig.baseUrl +
    //     SocketConfig.channels.notifications +
    //     `?token=${authToken}`

    //   this.notificationConnection = this.notificationConnection
    //     ? this.notificationConnection
    //     : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

    //   this.notificationConnection.onmessage = this.onMessageHandler
    // },
    // disconnectToWebsocket() {
    //   if (this.notificationConnection) {
    //     this.notificationConnection.close(
    //       4003,
    //       'oto system closed the socket as the was closed'
    //     )
    //     this.notificationConnection = null
    //   }
    // },
    // seenNotification(notification, index) {
    //   if (!notification['seen']) {
    //     setTimeout(() => {
    //       this.notificationConnection.send(
    //         JSON.stringify({
    //           noti_id: notification['id'],
    //         })
    //       )
    //       this.$store.commit('fsTable/modifyResponseData', {
    //         index: index,
    //         key: 'seen',
    //         value: true,
    //       })
    //     }, 3000)
    //   }
    // },
    // onMessageHandler(message) {
    //   let payload = JSON.parse(message.data)
    //   console.log('notification-incomming', payload)

    //   if (
    //     typeof payload['summary'] !== 'undefined' &&
    //     typeof payload['summary']['unseen'] !== 'undefined'
    //   ) {
    //     this.indexMetaData.summary.total = payload['summary']['total']
    //     this.indexMetaData.summary.unseen = payload['summary']['unseen']
    //     this.indexMetaData.summary.seen = payload['summary']['seen']

    //     if (payload.n_type !== 'noti.new') return
    //     if (payload['summary']['unseen'] > 0) {
    //       this.refreshPage()
    //     }
    //   }
    // },
    // getData(notifications) {
    //   if (notifications.length > 0) {
    //     notifications.map((notification, index) =>
    //       this.seenNotification(notification, index)
    //     )
    //   }
    //   return notifications
    // },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>
