<template>
  <slide-over-right
    :id="sorId"
    editType="whats-new"
    v-on:showModal="showModal"
    :fullWidth="isScreenFull"
  >
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <div class="flex flex-wrap  w-full">
        <div class="items-center  panel-title header-title">
          {{ titleText }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3">Save</t-button>
      </div>
    </template>

    <div class="px-6">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
        >
          <div class="flex items-center pt-2">
            <AppInput
              rules="required"
              name="Title"
              label="Title"
              type="text"
              v-model="form.systemVersion.title"
            />
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <AppInput
                rules="required"
                name="Version Number"
                label="Version Number"
                placeholder="e.g. 3.1.4.0"
                type="text"
                v-model="form.systemVersion.version"
              />
            </div>
            <div class="pl-2 w-1/2">
              <AppInput
                rules="required"
                v-model="form.systemVersion.release_date"
                type="date"
                name="Release Date"
                label="Release Date"
                placeholder=""
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                rules=""
                label="Release Description Url"
                placeholder="https://www.test.com/id"
                v-model="form.systemVersion.release_notes_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <AppInput
                v-model="form.systemVersion.status"
                type="richselect"
                name="Status"
                label="Status"
                rules="required"
                placeholder="Select"
                value-attribute="value"
                text-attribute="text"
                :options="systemVersionStatusChoices"
                hide-search-box
              />
            </div>
          </div>
          <section
            v-for="(releaseNote, releaseNoteIndex) in form.releaseNotes"
            :key="releaseNoteIndex"
          >
            <div>
              <div
                class="flex items-center my-5 text-sm font-bold text-gray-500"
              >
                <span>{{ getReleaseTitle(releaseNote.value) }}</span>
                <div
                  class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
                  @click="onAddNotes(releaseNoteIndex, releaseNote.value)"
                >
                  <i class="fas fa-plus-circle" style="color: black"></i>
                </div>
              </div>

              <div
                class="flex items-center  "
                v-for="(noteData, noteDataIndex) in releaseNote.data"
                :key="noteDataIndex"
              >
                <div class="flex gap-2 items-start  w-11/12  ">
                  <div class="w-full   ">
                    <AppInput
                      v-model="noteData.description"
                      type="text"
                      name="name"
                      :label="`Description ${noteDataIndex + 1}`"
                      rules="required"
                    />
                  </div>
                </div>

                <div class="flex justify-center w-1/12 item-center">
                  <div
                    class="col-span-1 cursor-pointer focus:text-gray-400"
                    @click="
                      onRemoveDescription(releaseNoteIndex, noteDataIndex)
                    "
                  >
                    <i class="fas fa-minus-circle" style="color: #d90a20"></i>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>
    </div>
  </slide-over-right>
</template>
<script>
import { WhatsNewConfig } from '@/config/WhatsNewConfig.js'
// import { EventBus } from '@/utils/EventBus'
import xMan from '@/utils/xMan'
import SlideOverRight from '@/components/modals/SlideOverRight'
// import TextInput from '@/components/form/TextInput'
import AnchorButton from '@/components/form/AnchorButton'

import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import { EventBus } from '@/utils'
import {
  generateReleaseNotesDataModel,
  choiceMapping,
} from '@/views/whats-new/shared/helper.js'
export default {
  name: 'WhatsNewAddEdit',
  components: {
    SlideOverRight,
    // TextInput,
    AnchorButton,
    SlideOverRightTrigger,
  },
  props: {
    isInitDataLoading: {
      type: Boolean,
      default: true,
    },
    isScreenFull: {
      type: Boolean,
      default: true,
    },
    systemVersionStatusChoices: {
      type: Array,
      default: () => [],
    },
    releaseNoteTypeChoices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    console.log('choiceMapping', choiceMapping)
    return {
      sorId: WhatsNewConfig.events.sorId,
      isLoading: false,
      form: {
        systemVersion: {},
        releaseNotes: [],
      },
      deleteReleaseNotes: [],
      releaseChoices: choiceMapping,
    }
  },
  computed: {
    isEditing: function() {
      // enabled status-switch by default
      return this.form.systemVersion.id
    },
    titleText() {
      return this.isEditing ? `Edit Release Note` : `Add Release Note`
    },
  },
  watch: {
    isInitDataLoading: {
      deep: false,
      immediate: true,
      handler(updatedData) {
        this.isLoading = updatedData
      },
    },
    releaseNoteTypeChoices: {
      deep: false,
      immediate: true,
      handler(updatedData) {
        if (updatedData.length > 0 && !this.isEditing) {
          this.form.releaseNotes = this.generateReleaseNotesDataModel(
            updatedData
          )
        }
      },
    },
  },
  mounted() {
    EventBus.$on(WhatsNewConfig.events.editingData, (data) => {
      this.init()

      if (data.itemId) {
        this.systemVersionId = data.itemId
        this.isLoading = true
        const systemVersionReq = this.$http.get(
          WhatsNewConfig.api.systemVersion.details(data.itemId)
        )

        const releaseNotesReq = this.$http.get(
          WhatsNewConfig.api.releaseNotes.details(data.itemId)
        )
        const initRequests = [systemVersionReq, releaseNotesReq]

        this.$http
          .all(initRequests)
          .then(
            this.$http.spread((...initResponses) => {
              this.form.systemVersion = initResponses[0].data
              this.form.systemVersion.release_date = this.dateSplitter(
                initResponses[0].data.release_date
              )
              this.form.releaseNotes = initResponses[1].data
              console.log('release-note-res', initResponses)
            })
          )
          .catch((err) => {
            console.error('Error occured!', err)
            this.$notify({
              group: 'bottomLeft',
              type: 'error',
              title: 'Error occured!',
              text: err.response.data.detail,
            })
          })
          .finally(() => (this.isLoading = false))
      }
    })
  },
  methods: {
    generateReleaseNotesDataModel,
    getReleaseTitle(type) {
      return this.releaseChoices[type]
    },
    dateSplitter(dString) {
      return dString.split('T')[0]
    },
    showModal() {
      dispatchEvent(new Event(WhatsNewConfig.events.sorToggle))
    },
    init() {
      this.form.systemVersion.id = null
      this.form.systemVersion.version = null
      this.form.systemVersion.title = null
      this.form.systemVersion.release_date = null
      this.form.systemVersion.status = null
      this.form.systemVersion.release_notes_url = null
      if (this.releaseNoteTypeChoices.length > 0) {
        this.form.releaseNotes = this.generateReleaseNotesDataModel(
          this.releaseNoteTypeChoices
        )
      }
    },
    onAddNotes(index, typeValue) {
      this.form.releaseNotes[index].data.unshift({
        description: '',
        type: typeValue,
      })
    },

    onRemoveDescription(parentIndex, childIndex) {
      if (this.isEditing) {
        this.deleteReleaseNotes.push(
          this.form.releaseNotes[parentIndex].data[childIndex]
        )
      }
      this.form.releaseNotes[parentIndex].data.splice(childIndex, 1)
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async confirmSubmit() {
      console.log('confirmSubmit', this.form)
      const requests = []

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? WhatsNewConfig.api.systemVersion.update(this.form.systemVersion.id)
        : WhatsNewConfig.api.systemVersion.create()

      const systemVersionFormProxy = {
        ...this.form.systemVersion,
        release_date: `${this.form.systemVersion.release_date}T00:00:00`,
      }

      const systemVersionPropertyNames = Object.keys(systemVersionFormProxy)
      systemVersionPropertyNames.map((name) => {
        if (systemVersionFormProxy[name] === null) {
          delete systemVersionFormProxy[name]
        }
      })

      let data = new xMan(systemVersionFormProxy).toFormData()

      this.isLoading = true

      await this.$http({
        method: method,
        url: url,
        data: data,
      })
        .then((response) => {
          let notesRequests = []
          if (response.data.id && this.form.releaseNotes.length > 0) {
            this.form.releaseNotes.forEach((releaseNote) => {
              if (releaseNote.data.length > 0) {
                releaseNote.data.forEach((noteData) => {
                  const isNoteDataUpdateReq = 'id' in noteData
                  const noteDataReqMethod = isNoteDataUpdateReq
                    ? 'PATCH'
                    : 'POST'
                  const noteDataReqUrl = isNoteDataUpdateReq
                    ? WhatsNewConfig.api.releaseNotes.update(noteData.id)
                    : WhatsNewConfig.api.releaseNotes.create()

                  const noteDataReqData = new FormData()
                  if (isNoteDataUpdateReq) {
                    noteDataReqData.append('description', noteData.description)
                  } else {
                    noteDataReqData.append('type', noteData.type)
                    noteDataReqData.append('description', noteData.description)
                    noteDataReqData.append('system_version', response.data.id)
                  }

                  const noteReq = this.$http({
                    url: noteDataReqUrl,
                    method: noteDataReqMethod,
                    data: noteDataReqData,
                  })
                  notesRequests.push(noteReq)
                })
              }
            })
          }
          requests.push(...notesRequests)

          let deleteReleaseNotesReqs = []
          if (this.isEditing && this.deleteReleaseNotes.length) {
            this.deleteReleaseNotes.forEach((element) => {
              if ('id' in element) {
                const deleteNoteReq = this.$http({
                  url: WhatsNewConfig.api.releaseNotes.delete(element.id),
                  method: 'DELETE',
                })
                deleteReleaseNotesReqs.push(deleteNoteReq)
              }
            })
          }
          if (deleteReleaseNotesReqs.length) {
            requests.push(...deleteReleaseNotesReqs)
          }

          this.$http
            .all(requests)
            .then(
              this.$http.spread((...responses) => {
                console.log('release-note-res', responses)
                let message = this.isEditing
                  ? 'Whats New updated successfully'
                  : 'Whats New added successfully'

                this.$notify({
                  group: 'bottomLeft',
                  type: 'success',
                  title: 'Success',
                  text: message,
                })
              })
            )
            .catch((err) => {
              // todo: emit fail?
              console.error('Error occured!', err)
              this.$notify({
                group: 'bottomLeft',
                type: 'error',
                title: 'Error occured!',
                text: err.response.data.detail,
              })
            })
            .finally((this.deleteReleaseNotes = []))

          dispatchEvent(new Event(WhatsNewConfig.events.sorToggle))
          dispatchEvent(new Event(WhatsNewConfig.events.refresh))
          this.$emit('add-edit')
          // Reset the form
          this.init()
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
<style lang="scss">
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
</style>
