<template>
  <base-layout>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            :title="$t('components.logsManagement.eventLogs.headline')"
            :hidePlus="true"
          />

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>

      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow
                :key="itemIndex"
                className="align-top fst-row fst-row-white"
                textFallbackAlways
              >
                <FSTableRowItem>
                  <div class="section-year">
                    <p class="year">
                      <span v-text="getLogTime(item)" class="date-time" />
                      <br />
                      <span
                        v-text="getLogDate(item)"
                        class="date-date"
                        style=""
                      />
                      <br />
                      <RouterLink
                        :to="getRouteDestination(item.target)"
                        target="_blank"
                        class="font-normal text-blue-600"
                        v-if="
                          item.target !== null &&
                            getRouteDestination(item.target)
                        "
                      >
                        #{{ getTargetShortId(item.target.instance_id) }}
                      </RouterLink>
                    </p>
                  </div>
                </FSTableRowItem>

                <FSTableRowItem>
                  <div
                    class="flex items-center justify-center bg-gray-200 rounded-full"
                    :class="'w-10 h-10'"
                  >
                    <OtoIcon
                      v-if="getEventIcon(item)"
                      :action-name="getEventIcon(item)"
                      :styles="{ marginLeft: '-5px', marginTop: '4px' }"
                    />
                    <i v-else class="fa fa-check-circle text-green-600" />
                  </div>
                </FSTableRowItem>

                <FSTableRowItem>
                  <div :class="getClassFor('defaultSlot')">
                    <slot
                      name="default"
                      v-bind="{ defaultSlotClass: getClassFor('defaultSlot') }"
                    >
                      <div>
                        <span v-html="getHtmlLogTitle(item)" />
                        :
                        <span
                          class="font-regular"
                          v-html="getLogMessageDescription(item)"
                        />
                      </div>

                      <slot
                        name="expandable"
                        v-bind="{
                          expandableSlotClass: getClassFor('expandableSlot'),
                        }"
                      >
                        <div :class="getClassFor('expandableSlot')">
                          <!-- toggle -->
                          <slot name="detailsToggle">
                            <button
                              :class="getClassFor('detailsToggle')"
                              @click="toggleDetails(item)"
                            >
                              {{ isDetailsExpanded(item) ? 'Hide' : 'Show' }}

                              Updated Fields

                              <svg
                                width="17"
                                height="8"
                                viewBox="0 0 17 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
                                  fill="#0954E4"
                                />
                              </svg>
                            </button>
                          </slot>
                          <!-- /toggle -->

                          <AccordionTransition
                            :expanded="isDetailsExpanded(item)"
                          >
                            <div class="variants secondary">
                              <div
                                class="flex items-center justify-between"
                                v-if="item.changed_fields === null"
                              >
                                <h3 class="font-weight: 500; font-size: 16px;">
                                  N/A
                                </h3>
                              </div>
                              <div
                                class="w-full my-2"
                                v-if="item.changed_fields !== null"
                              >
                                <div
                                  v-for="(changed_field_key,
                                  changed_field_idx) in Object.keys(
                                    item.changed_fields
                                  )"
                                  :key="`changed_field-${changed_field_idx}`"
                                  class="flex items-center gap-3 py-1 text-sm"
                                >
                                  <div class="w-2/4">
                                    <span
                                      class="font-medium text-gray-700"
                                      v-text="changed_field_key"
                                    />
                                  </div>

                                  <div class="w-2/4">
                                    {{
                                      sanitizeFieldValue(
                                        item.changed_fields[
                                          changed_field_key
                                        ][0]
                                      )
                                    }}
                                    →
                                    {{
                                      sanitizeFieldValue(
                                        item.changed_fields[
                                          changed_field_key
                                        ][1]
                                      )
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionTransition>
                        </div>
                      </slot>
                    </slot>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  v-if="item.actor !== null"
                  :text="item.actor.full_name"
                  :to="{
                    name: 'ViewOrganizationUserProfile',
                    params: { id: item.actor.id },
                  }"
                />
                <FSTableRowItem v-if="item.actor === null" text="--" />
                <FSTableRowItem
                  v-if="item.actor !== null"
                  :text="item.actor.role_name"
                />
                <FSTableRowItem v-if="item.actor === null" text="--" />
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <div class="section-year">
                    <p class="year">
                      <span v-text="getLogTime(item)" class="date-time" />
                      <br />
                      <span
                        v-text="getLogDate(item)"
                        class="date-date"
                        style=""
                      />
                      <br />
                      <RouterLink
                        :to="getRouteDestination(item.target)"
                        target="_blank"
                        class="font-normal text-blue-600"
                        v-if="
                          item.target !== null &&
                            getRouteDestination(item.target)
                        "
                      >
                        #{{ getTargetShortId(item.target.instance_id) }}
                      </RouterLink>
                    </p>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <div :class="getClassFor('defaultSlot')">
                    <slot
                      name="default"
                      v-bind="{ defaultSlotClass: getClassFor('defaultSlot') }"
                    >
                      <div>
                        <span v-html="getHtmlLogTitle(item)" />
                        :
                        <span
                          class="font-regular"
                          v-html="getLogMessageDescription(item)"
                        />
                      </div>

                      <slot
                        name="expandable"
                        v-bind="{
                          expandableSlotClass: getClassFor('expandableSlot'),
                        }"
                      >
                        <div :class="getClassFor('expandableSlot')">
                          <!-- toggle -->
                          <slot name="detailsToggle">
                            <button
                              :class="getClassFor('detailsToggle')"
                              @click="toggleDetails(item)"
                            >
                              {{ isDetailsExpanded(item) ? 'Hide' : 'Show' }}

                              Updated Fields

                              <svg
                                width="17"
                                height="8"
                                viewBox="0 0 17 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
                                  fill="#0954E4"
                                />
                              </svg>
                            </button>
                          </slot>
                          <!-- /toggle -->

                          <AccordionTransition
                            :expanded="isDetailsExpanded(item)"
                          >
                            <div class="variants secondary">
                              <div
                                class="flex items-center justify-between"
                                v-if="item.changed_fields === null"
                              >
                                <h3 class="font-weight: 500; font-size: 16px;">
                                  N/A
                                </h3>
                              </div>
                              <div
                                class="w-full my-2"
                                v-if="item.changed_fields !== null"
                              >
                                <div
                                  v-for="(changed_field_key,
                                  changed_field_idx) in Object.keys(
                                    item.changed_fields
                                  )"
                                  :key="`changed_field-${changed_field_idx}`"
                                  class="flex items-center gap-3 py-1 text-sm"
                                >
                                  <div class="w-2/4">
                                    <span
                                      class="font-medium text-gray-700"
                                      v-text="changed_field_key"
                                    />
                                  </div>

                                  <div class="w-2/4">
                                    {{
                                      sanitizeFieldValue(
                                        item.changed_fields[
                                          changed_field_key
                                        ][0]
                                      )
                                    }}
                                    →
                                    {{
                                      sanitizeFieldValue(
                                        item.changed_fields[
                                          changed_field_key
                                        ][1]
                                      )
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionTransition>
                        </div>
                      </slot>
                    </slot>
                  </div>
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.logsManagement.eventLogs.table.columns.eventType'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div
                        class="flex items-center justify-center bg-gray-200 rounded-full"
                        :class="'w-10 h-10'"
                      >
                        <OtoIcon
                          v-if="getEventIcon(item)"
                          :action-name="getEventIcon(item)"
                          :styles="{ marginLeft: '-5px', marginTop: '4px' }"
                        />
                        <i v-else class="fa fa-check-circle text-green-600" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.logsManagement.eventLogs.table.columns.actor'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div v-if="item.actor !== null" class="flex items-center">
                        <router-link
                          :to="{
                            name: 'ViewOrganizationUserProfile',
                            params: { id: item.actor.id },
                          }"
                          :class="`text-blue-600`"
                          target="_blank"
                        >
                          {{ item.actor.full_name }}
                        </router-link>
                      </div>
                      <div v-else>
                        --
                      </div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.logsManagement.eventLogs.table.columns.actorRole'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div>
                        {{ item.actor !== null ? item.actor.role_name : '--' }}
                      </div>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template></template
          >
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import { AuditLogsConfig } from '@/config/AuditLogsConfig'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import OtoIcon from '@/components/ui/OtoIcon.vue'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import AccordionTransition from '@/components/accordion/AccordionTransition.vue'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

import TitlePlus from '@/components/ui/TitlePlus.vue'
import startCase from 'lodash/startCase'

export default {
  name: 'VoucherIndex',
  components: {
    BaseLayout,
    AccordionTransition,
    ContentSection,
    DateRangePicker,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    TitlePlus,
    FSTableFilter,
    OtoIcon,
  },
  data() {
    return {
      indexMetaData: {
        summary: {},
      },
      qso: { append: '', prepend: '?' },
      fstId: 'auditLogsIndex',
      filterOptions: [],
      indexDataEndpoint: AuditLogsConfig.api.index,
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.logsManagement.eventLogs.table.columns.time'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.logsManagement.eventLogs.table.columns.eventType'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.logsManagement.eventLogs.table.columns.eventDetails'
          ),
          width: '57%',
          sort: null,
        },
        {
          text: this.$t(
            'components.logsManagement.eventLogs.table.columns.actor'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.logsManagement.eventLogs.table.columns.actorRole'
          ),
          width: '8%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.logsManagement.eventLogs.table.columns.time'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.logsManagement.eventLogs.table.columns.eventDetails'
          ),
          width: '70%',
          sort: null,
        },
      ],
      detailsExpandState: {},
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getLogTime(log) {
      return this.$UTCAwareTime(log?.created_at, {
        format: 'hh:mm a',
      })
    },
    getLogDate(log) {
      return this.$UTCAwareTime(log?.created_at, {
        format: 'DD MMM, YY',
      })
    },
    getClassFor(term = '') {
      const map = {
        defaultSlot: `defaultSlot ${this.variant}`,

        expandableSlot: `mt-2`,

        detailsToggle: 'flex items-center text-blue-600 gap-2 text-sm',
      }

      return map[term] || `no-class-found-for:${term}`
    },
    getActorString(actor) {
      if (!actor) return 'Unknown'

      return `${actor.full_name} - [${actor.role_name}]`
    },
    getActionVerb(eventType) {
      if (eventType === 'Create') {
        return 'created'
      }

      if (eventType === 'Update') {
        return 'updated'
      }

      if (eventType === 'Delete') {
        return 'deleted'
      }

      if (eventType === 'Many-to-Many Change') {
        return 'updated'
      }

      if (eventType === 'Reverse Many-to-Many Change') {
        return 'updated'
      }
    },
    getHtmlLogTitle(item) {
      const logType = `${this.getHumanReadableModelName(
        `${item.target.app}.${item.target.model}`
      ).toUpperCase()} ${item.event_type.toUpperCase()}D`

      return `<b class="font-bold">${logType}</b>`
    },
    getHumanReadableFieldName(fieldName) {
      const parts = fieldName.split('_').map((x) => startCase(x))

      return parts.join(' ')
    },
    getHumanReadableFieldsMessage(changedFields, eventType) {
      if (eventType === 'Create') {
        return 'a new record'
      }

      if (eventType === 'Delete') {
        return 'an existing record'
      }

      if (eventType === 'Many-to-Many Change') {
        // pass
      }

      if (eventType === 'Reverse Many-to-Many Change') {
        // pass
      }

      if (eventType === 'Update') {
        // pass
      }

      if (!changedFields || !Object.keys(changedFields).length) {
        return 'a record'
      }

      const fields = Object.keys(changedFields).map((x) =>
        this.getHumanReadableFieldName(x)
      )

      return fields.join(', ')
    },
    getLogMessageDescription(item) {
      const actionVerb = this.getActionVerb(item.event_type)
      const fieldsMessage = this.getHumanReadableFieldsMessage(
        item.changed_fields,
        item.event_type
      )
      return `${this.getActorString(
        item.actor
      )} has ${actionVerb} ${fieldsMessage}`
    },
    toggleDetails(item) {
      let val
      if (this.detailsExpandState[item.id]) {
        val = false
      } else {
        val = true
      }

      this.$set(this.detailsExpandState, item.id, val)
    },
    isDetailsExpanded(item) {
      return this.detailsExpandState[item.id] || false
    },
    onApplyFilterDateRange,
    getHumanReadableModelName(modelName) {
      const [, modelNameLower] = modelName.split('.')

      if (modelNameLower === 'organization') {
        return 'Organization Settings'
      }

      if (modelNameLower === 'organizationvehiclesettings') {
        return 'Organization Vehicle Settings'
      }

      if (modelNameLower === 'organizationeventsettings') {
        return 'Organization Event Settings'
      }

      if (modelNameLower === 'organizationbehaviorpoint') {
        return 'Organization Behavior Point Settings'
      }

      if (modelNameLower === 'organizationusergroup') {
        return 'Organization User Roles'
      }

      if (modelNameLower === 'rewardpoint') {
        return 'Organization Reward Point Settings'
      }

      if (modelNameLower === 'organizationtaskwagesettings') {
        return 'Organization Task Wage Settings'
      }

      if (modelNameLower === 'organizationbusinesshoursettings') {
        return 'Organization Business Hour Settings'
      }

      if (modelNameLower === 'fleet') {
        return 'Fleet Settings'
      }

      if (modelNameLower === 'pricingplan') {
        return 'Fleet Pricing Plan'
      }

      if (modelNameLower === 'bike') {
        return 'Vehicle'
      }

      if (modelNameLower === 'geofence') {
        return 'Service Area'
      }

      if (modelNameLower === 'parkingarea') {
        return 'Parking Area'
      }

      if (modelNameLower === 'restrictedarea') {
        return 'Restricted Area'
      }

      if (modelNameLower === 'slowzonearea') {
        return 'Slow Zone Area'
      }

      if (modelNameLower === 'user') {
        return 'User'
      }

      if (modelNameLower === 'promotion') {
        return 'Rider Promotion'
      }

      if (modelNameLower === 'subscription') {
        return 'Rider Subscription'
      }

      if (modelNameLower === 'ridervoucher') {
        return 'Rider Voucher'
      }

      if (modelNameLower === 'ridercampaign') {
        return 'Rider Campaign'
      }
    },
    async generateFilterOptions() {
      const filterableModels = []
      await this.$http
        .get(AuditLogsConfig.api.loggedModels)
        .then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((item) => {
              filterableModels.push({
                text: this.getHumanReadableModelName(item.text),
                value: item.value,
              })
            })
          }
        })

      this.filterOptions = [
        {
          key: 'model',
          type: 'checkbox',
          input: filterableModels,
          title: 'Model',
        },
        {
          key: 'event_type',
          type: 'checkbox',
          input: [
            { text: 'Create', value: '1' },
            { text: 'Update', value: '2' },
            { text: 'Delete', value: '3' },
            { text: 'Many-to-Many Change', value: '4' },
            { text: 'Reverse Many-to-Many Change', value: '5' },
          ],
          title: 'Event Type',
        },
      ]
    },
    getEventIcon(item) {
      if (item.event_type === 'Create') {
        return 'OPERATOR_TASK_BULK_COMPLETED'
      }

      if (item.event_type === 'Update') {
        return 'OPERATOR_TASK_TITLE_CHANGED'
      }

      if (item.event_type === 'Delete') {
        return 'OPERATOR_TASK_CANCELLED'
      }

      if (item.event_type === 'Many-to-Many Change') {
        return 'OPERATOR_TASK_TITLE_CHANGED'
      }

      if (item.event_type === 'Reverse Many-to-Many Change') {
        return 'OPERATOR_TASK_TITLE_CHANGED'
      }
    },
    getRouteDestination(target) {
      const targetIdStr = target.instance_id.toString()
      let screenName = ''

      if (target.app === 'core' && target.model === 'organization') {
        screenName = 'ViewOrganizationProfile'
      }

      if (target.app === 'core' && target.model === 'organizationusergroup') {
        return null
      }

      if (target.app === 'trip' && target.model === 'fleet') {
        screenName = 'ViewFleetProfile'
      }

      if (target.app === 'trip' && target.model === 'pricingplan') {
        return { name: 'Billing Management' }
      }

      if (target.app === 'trip' && target.model === 'bike') {
        screenName = 'ViewVehicleProfile'
      }

      if (target.app === 'trip' && target.model === 'geofence') {
        return null
      }

      if (target.app === 'trip' && target.model === 'promotion') {
        screenName = 'PromotionDetails'
      }

      if (target.app === 'otopass' && target.model === 'subscription') {
        screenName = 'PassDetails'
      }

      if (target.app === 'otovoucher' && target.model === 'ridervoucher') {
        screenName = 'VoucherDetails'
      }

      if (target.app === 'otocampaign' && target.model === 'ridercampaign') {
        screenName = 'MarketingCampaignDetails'
      }

      if (target.app === 'core' && target.model === 'organization') {
        screenName = 'GeneralSettings'
      }

      if (
        target.app === 'core' &&
        target.model === 'organizationvehiclesettings'
      ) {
        screenName = 'VehicleSettings'
      }

      if (
        target.app === 'core' &&
        target.model === 'organizationeventsettings'
      ) {
        screenName = 'EventSettings'
      }

      if (
        target.app === 'core' &&
        target.model === 'organizationbehaviorpoint'
      ) {
        screenName = 'PointSettings'
      }

      if (target.app === 'core' && target.model === 'rewardpoint') {
        screenName = 'PointSettings'
      }

      if (target.app === 'core' && target.model === 'organizationusergroup') {
        screenName = 'RoleIndex'
      }

      return {
        name: screenName,
        params: { id: targetIdStr },
      }
    },
    getTargetShortId(targetId) {
      const targetIdStr = targetId.toString()
      return targetIdStr.substr(targetIdStr.length - 5)
    },
    sanitizeFieldValue(value) {
      if (typeof value === 'string' && !value.trim().length) {
        return 'N/A'
      }

      if (value === null || typeof value === 'undefined') {
        return 'N/A'
      }

      return value
    },
  },
  async created() {
    await this.generateFilterOptions()
  },
}
</script>

<style lang="scss" scoped>
.section-year {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 15px 15px 0;
  font-weight: bold;
  font-size: 12px;
  .year {
    margin: 0;
    .date-time {
      color: #2e2e39;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .date-date {
      color: #909099;
      font-size: 13px;
      font-weight: 400;
    }
  }
}

.font-regular {
  font-weight: 400 !important;
}

.variants {
  @apply max-w-2xl p-4 mt-3;
  border-radius: 7px;

  &.light {
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
  }

  &.secondary {
    background: #f1f1f1;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
  }

  &.warning {
    background: #f9f0d7;
    border: 1px solid #f2c816;
    border-radius: 8px;
  }
}

.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
