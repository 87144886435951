<template>
  <div class="mt-3 md:mt-0">
    <t-dropdown variant="smActions">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          class="text-oDark focus:outline-none"
          aria-label="Action Menu For Marketplace"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <svg
            class="mt-1 w-6 h-5 text-oGray hover:text-oDark"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            ></path>
          </svg>
        </button>
      </template>

      <template v-slot:default="{ hide }">
        <div
          @click="hide()"
          class="py-1 mt-px bg-white rounded-md shadow-md z-200"
        >
          <t-dropdown-item class="font-medium text-14px" @click="onNotify">
            Notify
          </t-dropdown-item>
          <t-dropdown-item
            class="font-medium text-14px text-red-500 hover:bg-red-50"
            @click="onDelete"
          >
            Delete
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </div>
</template>

<script>
import { WhatsNewConfig } from '@/config/WhatsNewConfig'
export default {
  name: 'MoreActionsForWhatsNew',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default() {
        return {
          status: false,
        }
      },
    },
  },

  methods: {
    onNotify() {
      this.$notify({
        group: 'generic',
        type: 'default',
        title: 'Upcoming',
        text: 'Not operational yet',
      })
    },
    async onDelete() {
      await this.$http
        .delete(WhatsNewConfig.api.systemVersion.delete(this.data.id))
        .then(() => {
          this.refreshPage()
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Deleted',
              text: 'Delete Successfully',
            },
            3000
          )
        })
        .catch(() => {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: 'Action failed to execute.',
            },
            3000
          )
        })
    },
    refreshPage() {
      this.$emit('refresh')
    },
  },
}
</script>

<style lang="scss" scoped></style>
