<template>
  <base-layout>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
          <title-plus
            :title="$t('components.notificationManagement.headline')"
            :hide-plus="true"
          />

          <div class="md:flex md:justify-end">
            <DateRangePicker
              :time-filter-enabled="true"
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="$t('components.notificationManagement.summary.total')"
            :value="indexMetaData.summary.total"
            variant="gray"
          />
          <SummaryCard
            :title="$t('components.notificationManagement.summary.unseen')"
            :value="indexMetaData.summary.unseen"
            variant="gray"
          />
          <SummaryCard
            :title="$t('components.notificationManagement.summary.seen')"
            :value="indexMetaData.summary.seen"
            variant="gray"
          />
        </div>
      </div>

      <template>
        <FSTable
          :fst-id="fstId"
          :qso="qso"
          :endpoint="indexDataEndpoint"
          :headers="getTableHeaders"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #topLeft="{ slotWidth }">
            <keep-alive>
              <FSTableFilter
                :fst-id="fstId"
                :slot-width="slotWidth"
                :options="filterOptions"
              />
            </keep-alive>
          </template>

          <template #default="{ data }">
            <template v-for="(item, itemIndex) in getData(data)">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" textFallbackAlways>
                  <FSTableRowItem>
                    <div class="section-year">
                      <p class="year">
                        <span v-text="getLogTime(item)" class="date-time" />
                        <br />
                        <span
                          v-text="getLogDate(item)"
                          class="date-date"
                          style=""
                        />
                        <br />
                      </p>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <div
                      class="flex items-center justify-center bg-gray-200 rounded-full"
                      :class="'w-10 h-10'"
                    >
                      <CustomIcon
                        v-if="getIcon(item) !== ''"
                        :icon="getIcon(item)"
                        :customClass="item.seen ? `opacity-50` : `opacity-100`"
                      />
                      <span v-else>{{ item['subcategory'] }}</span>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <span class="font-bold">{{ getType(item) }}</span> -
                    <span
                      :class="item['seen'] ? 'font-normal' : 'font-bold'"
                      v-html="message(item)"
                    ></span>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <span v-if="item.extra_data.location">
                      <a
                        :href="getGoogleMapLink(item.extra_data.location)"
                        target="_blank"
                        class="cursor-pointer vehicle-map-icon"
                        ><i class="fas fa-map"
                      /></a>
                    </span>
                    <span v-else>--</span>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <span
                      v-if="item['stack_count'] > 1"
                      class="text-red-500 font-extrabold text-base"
                    >
                      {{ item['stack_count'] }}
                    </span>
                    <span v-else>--</span>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <TimeAgo
                      :title="item.updated_at"
                      :datetime="item.updated_at"
                      :auto-update="60"
                    />
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  textFallbackAlways
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <div class="section-year">
                      <p class="year">
                        <span v-text="getLogTime(item)" class="date-time" />
                        <br />
                        <span
                          v-text="getLogDate(item)"
                          class="date-date"
                          style=""
                        />
                        <br />
                      </p>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <span class="font-bold">{{ getType(item) }}</span> -
                    <span
                      :class="item['seen'] ? 'font-normal' : 'font-bold'"
                      v-html="message(item)"
                    ></span>
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.notificationManagement.table.columns.type'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div
                          class="flex items-center justify-center bg-gray-200 rounded-full"
                          :class="'w-10 h-10'"
                        >
                          <CustomIcon
                            v-if="getIcon(item) !== ''"
                            :icon="getIcon(item)"
                          />
                          <span v-else>{{ item['subcategory'] }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.notificationManagement.table.columns.location'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <span v-if="item.extra_data.location">
                          <a
                            :href="getGoogleMapLink(item.extra_data.location)"
                            target="_blank"
                            class="cursor-pointer vehicle-map-icon"
                            ><i class="fas fa-map"
                          /></a>
                        </span>
                        <span v-else>--</span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.notificationManagement.table.columns.count'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <span
                          v-if="item['stack_count'] > 1"
                          class="text-red-500 font-extrabold text-base"
                        >
                          {{ item['stack_count'] }}
                        </span>
                        <span v-else>--</span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.notificationManagement.table.columns.ago'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <TimeAgo
                          :title="item.updated_at"
                          :datetime="item.updated_at"
                          :auto-update="60"
                        />
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </base-layout>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import SummaryCard from '@/components/cards/SummaryCard'
import BaseLayout from '@/views/shared/BaseLayout.vue'
// import { useGeocoder } from '@/composables/vendor/useGeocoder'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'
import CustomIcon from '@/components/CustomIcon'
import { mapGetters } from 'vuex'
import { SocketConfig } from '@/config/SocketConfig'

import ReconnectingWebsocket from 'reconnectingwebsocket'
export default {
  name: 'NotificationIndex',
  components: {
    ContentSection,
    TitlePlus,
    BaseLayout,
    SummaryCard,
    CustomIcon,
    DateRangePicker,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
  },
  data() {
    return {
      fstId: 'notification-index',
      qso: { append: '', prepend: '?' },
      indexDataEndpoint: useEndpoints.notification.index(),
      notificationConnection: null,
      windowWidth: window.innerWidth,
      unseenIndexes: [],
      indexMetaData: {
        summary: {
          total: 0,
          unseen: 0,
          seen: 0,
        },
      },
      tableHeaders: [
        {
          text: this.$t('components.notificationManagement.table.columns.time'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.notificationManagement.table.columns.type'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.notificationManagement.table.columns.details'
          ),
          width: '55%',
          sort: null,
        },
        {
          text: this.$t(
            'components.notificationManagement.table.columns.location'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.notificationManagement.table.columns.count'
          ),
          width: '5%',
          sort: null,
        },
        {
          text: this.$t('components.notificationManagement.table.columns.ago'),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.notificationManagement.table.columns.time'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.notificationManagement.table.columns.details'
          ),
          width: '65%',
          sort: null,
        },
      ],
      opened: [],

      // generated by generateFilterOptions()
      filterOptions: [],
    }
  },
  async created() {
    this.connectToWebsocket()
    await this.generateFilterOptions()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.disconnectToWebsocket()
  },

  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    connectToWebsocket() {
      console.log('Connecting to Websocket from NotificationIndex')
      const authToken = localStorage.getItem('token') || null
      if (!authToken) {
        console.error('Cannot connect to notification because not logged in')
        return
      }
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.notifications +
        `?token=${authToken}`

      this.notificationConnection = this.notificationConnection
        ? this.notificationConnection
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.notificationConnection.onmessage = this.onMessageHandler
    },
    disconnectToWebsocket() {
      if (this.notificationConnection) {
        this.notificationConnection.close(
          4003,
          'oto system closed the socket as the was closed'
        )
        this.notificationConnection = null
      }
    },
    seenNotification(notification, index) {
      if (!notification['seen']) {
        setTimeout(() => {
          this.notificationConnection.send(
            JSON.stringify({
              noti_id: notification['id'],
            })
          )
          this.$store.commit('fsTable/modifyResponseData', {
            index: index,
            key: 'seen',
            value: true,
          })
        }, 3000)
      }
    },
    onMessageHandler(message) {
      let payload = JSON.parse(message.data)
      console.log('notification-incomming', payload)

      if (
        typeof payload['summary'] !== 'undefined' &&
        typeof payload['summary']['unseen'] !== 'undefined'
      ) {
        this.indexMetaData.summary.total = payload['summary']['total']
        this.indexMetaData.summary.unseen = payload['summary']['unseen']
        this.indexMetaData.summary.seen = payload['summary']['seen']

        if (payload.n_type !== 'noti.new') return
        if (payload['summary']['unseen'] > 0) {
          this.refreshPage()
        }
      }
    },
    getData(notifications) {
      if (notifications.length > 0) {
        notifications.map((notification, index) =>
          this.seenNotification(notification, index)
        )
      }
      return notifications
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    // async getGeoLocation(location) {
    //   const [lat, lng] = location?.split(',')?.map((n) => parseFloat(n))
    //   await useGeocoder({ lat, lng }).then((res) => {
    //     console.log('location', res.data.display_name)
    //     return Promise.resolve(res.data.display_name)
    //   })
    //   // return '----'
    // },
    getGoogleMapLink(location) {
      const [lat, lng] = location?.split(',')?.map((n) => parseFloat(n))
      return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    },
    getLogTime(log) {
      return this.$UTCAwareTime(log?.updated_at, {
        format: 'hh:mm:ss a',
      })
    },
    getLogDate(log) {
      return this.$UTCAwareTime(log?.updated_at, {
        format: 'DD MMM, YY',
      })
    },
    getIcon(item) {
      switch (item['subcategory']) {
        case 'IM':
          return 'illegal-movement'
        case 'DM':
          return 'illegal-movement'
        case 'ID':
          return 'illegal-demolished'
        case 'SU':
          return 'suspicious-unlocked'
        case 'IV':
          return 'idle-vehicle'
        case 'CB':
          return 'critical-battery'
        case 'IR':
          return 'iot-removed'
        case 'FD':
          return 'fallen-down'
        case 'PU':
          return 'pull-up'
        case 'LZ':
          return 'low-speed-zone'
        case 'RZ':
          return 'restricted-area'
        case 'OS':
          return 'out-of-service-area'
        case 'PO':
          return 'illegally-parked'
        case 'NB':
          return 'negative-balance'
        case 'UR':
          return 'report-from-user-app'
        case 'VF':
          return 'report-doc-verification'
        default:
          return ''
      }
    },
    getType(item) {
      switch (item['subcategory']) {
        case 'IM':
          return this.$t(
            'components.notificationManagement.table.type.illegalMovementIoTSensor'
          )
        case 'DM':
          return this.$t(
            'components.notificationManagement.table.type.illegalMovementDistance'
          )
        case 'ID':
          return this.$t(
            'components.notificationManagement.table.type.illegalDemolition'
          )
        case 'SU':
          return this.$t(
            'components.notificationManagement.table.type.suspiciousUnlocked'
          )
        case 'IV':
          return this.$t(
            'components.notificationManagement.table.type.idleVehicle'
          )
        case 'CB':
          return this.$t(
            'components.notificationManagement.table.type.criticalBattery'
          )
        case 'IR':
          return this.$t(
            'components.notificationManagement.table.type.iotRemovedDamaged'
          )
        case 'FD':
          return this.$t(
            'components.notificationManagement.table.type.fallenDown'
          )
        case 'PU':
          return this.$t('components.notificationManagement.table.type.pullUp')
        case 'LZ':
          return this.$t(
            'components.notificationManagement.table.type.lowSpeedZone'
          )
        case 'RZ':
          return this.$t(
            'components.notificationManagement.table.type.restrictedZone'
          )
        case 'OS':
          return this.$t(
            'components.notificationManagement.table.type.outOfServiceArea'
          )
        case 'PO':
          return this.$t(
            'components.notificationManagement.table.type.illegallyParked'
          )
        case 'NB':
          return this.$t(
            'components.notificationManagement.table.type.negativeBalance'
          )
        case 'UR':
          return this.$t(
            'components.notificationManagement.table.type.reportFromRiderApp'
          )
        case 'VF':
          return this.$t(
            'components.notificationManagement.table.type.reportDocVerification'
          )
        default:
          return ''
      }
    },
    linkHtml(idType, id, shorten = false) {
      let shortId
      if (typeof id === 'undefined') {
        return '<span class="text-red-600" >Id Unknown</span>'
      }
      if (id === null) {
        return ''
      }
      if (shorten) {
        const idLength = id.length
        shortId = id.slice(idLength > 5 ? id.length - 5 : 0)
      }
      if (idType === 'rider') {
        return `<a  href="/riders/${id}/profile" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
      if (idType === 'report') {
        return `<a  href="/reports/${id}" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
      if (idType === 'trip') {
        return `<a  href="/trips/${id}" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
      if (idType === 'restricted-zone') {
        return `<a  href="/geofence/restricted-areas/${id}" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
      if (idType === 'service-zone') {
        return `<a  href="/geofence/service-areas/${id}" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
      if (idType === 'slow-zone') {
        return `<a  href="/geofence/slow-zone-areas/${id}" class="text-blue-600 " target="_blank">#${shortId}</a>`
      }
    },
    linkHtmlForRider(id, phone) {
      if (typeof id === 'undefined' || typeof phone === 'undefined') {
        return '<span class="text-red-600" >Number Unknown</span>'
      }
      if (id === null || phone === null) {
        return ''
      }

      return `<a  href="/riders/${id}/profile" class="text-blue-600 " target="_blank">${phone}</a>`
    },
    linkHtmlForVehicle(id, qr) {
      if (typeof id === 'undefined' || typeof qr === 'undefined') {
        return '<span class="text-red-600" >Id Unknown</span>'
      }
      if (id === null || qr === null) {
        return ''
      }

      return `<a  href="/vehicle-management/vehicles/${id}/profile" class="text-blue-600 " target="_blank">#${qr}</a>`
    },
    toCurrency(amount) {
      const currencySmbol = this.organizationInfo.default_currency
        ? this.organizationInfo.default_currency.symbol
        : '$'
      if (amount * 1 >= 0) {
        return currencySmbol + '' + amount
      } else {
        return '-' + currencySmbol + '' + (amount * -1).toFixed(2)
      }
    },
    getDocType(type) {
      switch (type) {
        case 'D':
          return 'Driving License'
        case 'P':
          return 'Passport'
        case 'N':
          return 'National ID'
        case 'S':
          return 'Student ID'
        case 'O':
          return 'Official ID'
        default:
          return ''
      }
    },
    message(notification) {
      if (
        typeof notification['extra_data'] !== 'object' ||
        Object.keys(notification['extra_data']).length === 0
      ) {
        console.error(
          'There is problem with the notification extra_data',
          notification
        )
      }
      const extraData = notification['extra_data']

      const bikeQrCode = this.linkHtmlForVehicle(
        typeof extraData['bike_id'] !== 'undefined'
          ? extraData['bike_id']
          : null,
        typeof extraData['bike_qr_code'] !== 'undefined'
          ? extraData['bike_qr_code']
          : null
      )

      const userId = this.linkHtmlForRider(
        typeof extraData['user_id'] !== 'undefined'
          ? extraData['user_id']
          : null,
        typeof extraData['phone_number'] !== 'undefined'
          ? extraData['phone_number']
          : null
      )
      const fileType = this.getDocType(extraData.card_type)
      switch (notification['subcategory']) {
        case 'IM':
          return this.$t(
            'components.notificationManagement.table.typeDescription.illegalMovementIoTSensor',
            {
              bikeQrCode: bikeQrCode,
              userId: userId ? userId : 'Unknown',
            }
          )
        case 'DM':
          return this.$t(
            'components.notificationManagement.table.typeDescription.illegalMovementDistance',
            {
              bikeQrCode: bikeQrCode,
              userId: userId ? userId : 'Unknown',
            }
          )
        case 'SU':
          return this.$t(
            'components.notificationManagement.table.typeDescription.suspiciousUnlocked',
            {
              bikeQrCode: bikeQrCode,
            }
          )
        case 'ID':
          return this.$t(
            'components.notificationManagement.table.typeDescription.illegalDemolition',
            {
              bikeQrCode: bikeQrCode,
            }
          )
        case 'IV':
          return this.$t(
            'components.notificationManagement.table.typeDescription.idleVehicle',
            {
              bikeQrCode: bikeQrCode,
            }
          )
        case 'CB':
          return this.$t(
            'components.notificationManagement.table.typeDescription.criticalBattery',
            {
              bikeQrCode: bikeQrCode,
              powerLevel: extraData['power_level'],
            }
          )
        case 'IR':
          return this.$t(
            'components.notificationManagement.table.typeDescription.iotRemovedDamaged',
            {
              bikeQrCode: bikeQrCode,
              userId: userId,
            }
          )
        case 'FD':
          return this.$t(
            'components.notificationManagement.table.typeDescription.fallenDown',
            {
              bikeQrCode: bikeQrCode,
              userId: userId,
            }
          )
        case 'PU':
          return this.$t(
            'components.notificationManagement.table.typeDescription.pullUp',
            {
              bikeQrCode: bikeQrCode,
              userId: userId,
            }
          )
        case 'LZ':
          return this.$t(
            'components.notificationManagement.table.typeDescription.lowSpeedZone',
            {
              bikeQrCode: bikeQrCode,
              userId: userId,
              link: this.linkHtml('slow-zone', extraData['area_id'], true),
            }
          )
        case 'RZ':
          return this.$t(
            'components.notificationManagement.table.typeDescription.restrictedZone',
            {
              bikeQrCode: bikeQrCode,
              link: this.linkHtml(
                'restricted-zone',
                extraData['area_id'],
                true
              ),
            }
          )
        case 'OS':
          return this.$t(
            'components.notificationManagement.table.typeDescription.outOfServiceArea',
            {
              bikeQrCode: bikeQrCode,
              link: this.linkHtml('service-zone', extraData['area_id'], true),
            }
          )
        case 'PO':
          return this.$t(
            'components.notificationManagement.table.typeDescription.illegallyParked',
            {
              bikeQrCode: bikeQrCode,
              link: this.linkHtml('service-zone', extraData['area_id'], true),
            }
          )
        case 'NB':
          return this.$t(
            'components.notificationManagement.table.typeDescription.negativeBalance',
            {
              userId: userId,
              amount: this.toCurrency(extraData['balance']),
              link: this.linkHtml('trip', extraData['trip_id'], true),
            }
          )

        case 'UR':
          return this.$t(
            'components.notificationManagement.table.typeDescription.reportFromRiderApp',
            {
              userId: userId,
              link: this.linkHtml('report', extraData['report_id'], true),
            }
          )
        case 'VF':
          return this.$t(
            'components.notificationManagement.table.typeDescription.reportDocVerification',
            {
              userId: userId,
              fileType: fileType,
            }
          )
        default:
          return ''
      }
    },
    refreshPage() {
      this.$store.dispatch('fsTable/fetchData')
    },
    async generateFilterOptions() {
      let filters = [
        {
          type: 'checkbox',
          key: 'category',
          input: [
            { text: 'Vehicle', value: 'V' },
            { text: 'Area', value: 'A' },
            { text: 'eWallet', value: 'W' },
            { text: 'Report', value: 'R' },
          ],
          title: 'Category',
        },
        {
          type: 'checkbox',
          key: 'subcategory',
          input: [
            { text: 'Illegal Movement (IOT Sensor)', value: 'IM' },
            { text: 'Illegal Movement (Distance)', value: 'DM' },
            { text: 'Illegal Demolition', value: 'ID' },
            { text: 'Suspicious Unlocked', value: 'SU' },
            { text: 'Idle Vehicle', value: 'IV' },
            { text: 'Critical Battery', value: 'CB' },
            { text: 'IOT Removed/Damaged', value: 'IR' },
            { text: 'Fallen Down', value: 'FD' },
            { text: 'Pull Up', value: 'PU' },
            { text: 'Low Speed Zone', value: 'LZ' },
            { text: 'Restricted Zone', value: 'RZ' },
            { text: 'Out of Service Area', value: 'OS' },
            { text: 'Illegally Parked', value: 'PO' },
            { text: 'Negative Balance', value: 'NB' },
            { text: 'Report from Rider App', value: 'UR' },
            { text: 'Report Doc Verification', value: 'VF' },
          ],
          title: 'Subcategory',
        },
        {
          type: 'checkbox',
          key: 'read_receipt',
          input: [
            { text: 'Unseen', value: 'unseen' },
            { text: 'Seen', value: 'seen' },
          ],
          title: 'Visibility',
        },
      ]
      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },

    onApplyFilterDateRange,
  },
}
</script>
<style lang="scss" scoped>
.section-year {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 15px 15px 0;
  font-weight: bold;
  font-size: 12px;
  .year {
    margin: 0;
    .date-time {
      color: #2e2e39;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .date-date {
      color: #909099;
      font-size: 13px;
      font-weight: 400;
    }
  }
}
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
.vehicle-map-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  width: 27px !important;
  height: 27px !important;
  border-radius: 5px;

  cursor: pointer;
  margin-right: 0.5rem;
  @apply bg-blue-500;
}
</style>
