export function generateReleaseNotesDataModel(choices) {
  const generatedData = choices.map((choice) => {
    return {
      text: choiceMapping[choice.value]
        ? choiceMapping[choice.value]
        : choice.text,
      value: choice.value,
      data: [],
    }
  })

  return generatedData
}
export const choiceMapping = {
  BF: 'Bug Fixing',
  F: 'New Feature',
  E: 'Enhancement',
}
